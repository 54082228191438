@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.profile-content{
  
    border-bottom: 100vh solid #594B3E;
	border-left: 100px solid transparent;
    
	height: 100vh;
    background-color:transparent;
    
	
}

.content-more{
    color: white;
}

.logo-rida{
    width: 200px;
  
    
}
.rida-profile{
  width: 300px;
 
}
.img-portfolio{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;

}
.main-text{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 50vh;

}
.intro-title{
    font-family: "Poppins", serif;
    font-weight: 400;
    font-style: normal;
    color: #594B3E;
}
.profession, .profession-architect{
    font-family: "Poppins", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3.8rem;
    color: #594B3E;

}
.profession-architect{
    margin-top: -10px;
}

@media (max-width: 767px){
    .home{
        flex-direction: column-reverse;
    }
    .profile-content{
    border-bottom: 0 solid #594B3E;
    background-color:  #594B3E;
    height: 80vh;
    width: 100%;
	border-left: 0 solid transparent;
    

    }
    .img-portfolio {
        text-align: center ;
    }
    .main-text{
        justify-content: center;
    }
    .profession, .profession-architect{
        
        font-size: 2.8rem;
        
    
    }
  

    
    
}