.projects-1{
    position: relative;
    
   
    width: 100%;
    height: 200px !important;
    object-fit: cover;
}
.landscape-1{
   
    width: 100%;
    height: 200px !important;
    object-fit: cover;
   
   
   

}


  


.text-project{
    display: none;
}
.projects-1:hover + .text-project{
    display: block;
    position: absolute;
    transition: opacity ease 200ms;
    

}
.img-over{
    position: relative;
}
.text-project1{
    position: absolute;
     top: 50%;
    left: 50%;
     transform: translate(-50%, -50%);
}



  .hover-card {
    position: relative;
    /* width: 100%; 
    height: 250px; */
    overflow: hidden;
    cursor: pointer;
  }

  .hover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1); 
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  
  
  .hover-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); 
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1; 
    transition: opacity 0.3s ease-in-out;
  }
  
 
  .hover-text {
    font-size: 24px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }
  
  
  .hover-card:hover .hover-image {
    transform: scale(1.1);
    opacity: 0; 
  }
  
  .hover-card:hover .hover-overlay {
    opacity: 0; 
  }
  

  