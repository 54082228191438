


   .header {
   
    width: 100%;
    height: 72px;
  
    
  }
  .cross, .hamburger{
  
    color: #fff !important;
    
  }
  
  .nav-contain {
    margin-top: 8px;
    margin-bottom: 10px;
  }
  
  .navbar-brand {
    font-size: 2rem;
  }
  
  .header .navbar-toggler {
    border: none;
  }
  
  .header .navbar-toggler:focus {
    box-shadow: none;
  }
  
  .navbar-toggler>.hamburger {
    display: none;
  }
  
  .collapsed>.hamburger {
    display: block;
  }
  
  .collapsed>.cross {
    display: none;
  }
  
  .nav-custom-edits {
    text-decoration: none;
    color:#fff;
    font-size: 18px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
  }
  
  
  
  
  
 
  
  @media only screen and (max-width: 992px) {
    .navbar-brand img {
      width: 70px;
    }
  
    .navbar-nav {
      background-color: #594B3E;
    }
    
    
  }
  
  
  
 
  
 
  
  