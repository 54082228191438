.about-description{
    text-align: justify;
    font-family: "Poppins", serif;
    line-height: 30px;
}

.about-title{
    color: #D0AB69;
    font-family: "Poppins", serif;
    font-size: 3rem;
    font-weight: 400;
}